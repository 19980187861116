import React, {useEffect, useState} from 'react';

import {Plugins, Capacitor} from '@capacitor/core';

import Ingredients from './components/Ingredients/Ingredients';
import {checkNotificationSubscription, fb, auth, messaging} from './firebase'
import {setupPushNotifications} from './pushNotifications';
import {cfaSignIn} from 'capacitor-firebase-auth';


const App = props => {

  const { PushNotifications } = Plugins;
  
  const [user, setUser] = useState(null);

  const signOut = () => {
    setUser(null);
    auth.signOut()
    .then(() => {
      console.log('Signed Out');
      //history.push('/');
    })
    .catch(e=>{
     console.error('Sign Out Error', e);
    });
    
  }

  
 /*  useEffect(() => {
     // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then( result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration',
      (token) => {
        alert('Push registration success, token: ' + token.value);
        updateNotificationToken(token.value, user);
      }
    );

    PushNotifications.addListener('registrationError',
      (error) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    PushNotifications.addListener('pushNotificationReceived',
      (notification) => {
        alert('Push received: ' + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification) => {
        alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  
  },[]); */
  
  


  useEffect(() => {
     auth.onAuthStateChanged((user) => {
      if (!user) {
        cfaSignIn('google.com').subscribe(
          (user) => {
            
            auth.updateCurrentUser(user);
            
              
          })
        
      } else {
        
        setUser({id: user.uid, name: user.displayName.split(" ")[0], photoURL: user.photoURL});
        if (Capacitor.getPlatform() !== 'web') {
          setupPushNotifications(user.uid,setUser);
        } else {
          if (messaging) {
            const setupWebPush = async () => {
              await checkNotificationSubscription(user.uid);
            }
            setupWebPush(); 
            
          }
        }
        
      }
    }) 
    
    const signin = async () => {
      const result = await Plugins.GoogleAuth.signIn();
      console.info('result', result);
    }
    //signin(); 

  },[]);

  let footer = null;
  if (user) {
    footer = (<>
              
              <button className='button-white' style={{float:'right', width:'100%'}} onClick={signOut}>
                <img className='avatar' src={user.photoURL} />
                Sign Out</button>
              </>
            )
  }
  return (
    <>
      <Ingredients user={user} />
      <div className='ingredient-list'>
      {footer}
      
      </div>
    </>
    
  );
};

export default App;
