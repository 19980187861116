import React, {useState, useEffect, useCallback} from 'react';

import IngredientForm from './IngredientForm';
import IngredientList from './IngredientList';
import ErrorModal from '../UI/ErrorModal'
import Search from './Search';
import logo from '../../assets/images/logo.png'
import {firestore, serverTimestamp} from '../../firebase';


let unsubscribe = null;

const Ingredients = ({user}) => {
 
  const [ ingredients, setIngredients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  
  useEffect(() => {
    setIsLoading(true);
    const getGroceries = async () => {
      unsubscribe = firestore.collection('groceries').orderBy('created','desc').onSnapshot((refs) => {
        let results = [];
         
          refs.forEach(function(doc) {
            results.push({id: doc.id, ...doc.data()})
          });
            
          setIngredients(results);
          setIsLoading(false);
      })
    }
    getGroceries();
    return(() => unsubscribe());
  
    } 
  
   
  ,[])

  

  const addIngredientHandler = async ingredient => {
    setIsLoading(true);
    await firestore.collection('groceries').add({user_id: user.id, username: user.name, created: serverTimestamp(), ...ingredient});
    //setIngredients(prevIngredients => [{id: responseData.name, ...ingredient }, ...prevIngredients])
    setIsLoading(false);
   
    
  }

  const filteredIngredientsHandler = useCallback(filteredIngredients => {
    setIngredients(filteredIngredients);
  },[])

  const removeIngredientHandler = async id => {
    setIsLoading(true);
    await firestore.collection('groceries').doc(id).delete();
    setIsLoading(false);
  
    
  }

  const clearError = () => {
    setError(null);
    setIsLoading(false);
  }
  
  return (
    <div className="App">
      { error && <ErrorModal onClose={clearError}>{error}</ErrorModal>}
      <img src={logo} style={{display: 'block', margin: 'auto', width: '30rem', maxWidth: '80%', paddingTop: '10px'}} alt="Groceries" />
      <IngredientForm onAddIngredient={addIngredientHandler} loading={isLoading}  />

      <section>
        { true ? null : <Search onLoadIngredients={filteredIngredientsHandler}/> }
        <IngredientList ingredients={ingredients} onRemoveItem={removeIngredientHandler} />
      </section>
    </div>
  );
}

export default Ingredients;
