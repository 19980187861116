import React, { useState} from 'react';
import LoadingIndicator from '../UI/LoadingIndicator'

import Card from '../UI/Card';
import './IngredientForm.css';

const IngredientForm = React.memo(props => {
  
  const [enteredTitle, setEnteredTitle] = useState('');
  const [enteredAmount, setEnteredAmount] = useState('');
  
  const submitHandler = event => {
    event.preventDefault();
    props.onAddIngredient({
      title: enteredTitle,
      amount: enteredAmount
    })
    setEnteredTitle('');
    setEnteredAmount('');
    // ...
  };

  return (
    <section className="ingredient-form">
      <Card>
        <form onSubmit={submitHandler}>
          <div className="form-control">
            <label htmlFor="title">Name</label>
            <input type="text" 
                   id="title"
                   value={enteredTitle} 
                   onChange={e => setEnteredTitle(e.target.value)}
                   autoComplete="none"
            />
          </div>
         
          
          <div className="ingredient-form__actions">
            <button className='button-primary' type="submit">Add Item</button>
            {props.loading && <LoadingIndicator />}
          </div>
        </form>
      </Card>
    </section>
  );
});

export default IngredientForm;
