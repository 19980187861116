import React from "react";
import { Capacitor, Plugins } from "@capacitor/core";
import { firestore, serverTimestamp } from "./firebase";



const { PushNotifications } = Plugins;

let alreadyRegistered = false;

export const setupPushNotifications = (user_id, setCurrentUser) => {
  // Request permission to use push notifications
  // iOS will prompt user and return if they granted permission or not
  // Android will just grant without prompting
  
  PushNotifications.requestPermission().then((result) => {
    if (result.granted) {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      // Show some error
    }
  });

  PushNotifications.addListener("registration", (token) => {
    //alert('Push registration success, token: ' + token.value);
    if (!alreadyRegistered) {
      alreadyRegistered = true;
      console.log("Native push registration success.");
      updateNotificationToken(token.value, user_id);
      
      /* setCurrentUser((p) => {
        console.log('setting currentUser token:', token.value);
        return { ...p, messagingToken: token.value };
      }); */
    }
   
  });

  PushNotifications.addListener("registrationError", (error) => {
    alert("Error on registration: " + JSON.stringify(error));
  });

  PushNotifications.addListener(
    "pushNotificationReceived",
    (pushNotification) => {
      //alert('Push received: ' + JSON.stringify(pushNotification));
     console.log('Push received: ' + JSON.stringify(pushNotification));
    }
  );

  PushNotifications.addListener(
    "pushNotificationActionPerformed",
    (notification) => {
      console.log("push notification action performed");
      //alert('Push action performed: ' + JSON.stringify(notification));
    }
  );

  
};

const updateNotificationToken = async (token, user_id) => {
  try {
    //console.log("This Device Token:",token);
    const storedTokens = await firestore
      .collection("tokens")
      .where("token", "==", token)
      .where("user_id", "==", user_id)
      .get();
    console.log("storedTokens", storedTokens.docs.length);
    if (storedTokens.docs.length > 0) {
      //this is already in the system
      console.log("token already in firestore");
    } else {
      console.log("adding token to firestore");
      await firestore
        .collection("tokens")
        .add({ user_id: user_id, token: token, created: serverTimestamp() });
    }
    return token;
  } catch (error) {
    console.log("Error updating Notification Token", error);
    return null;
  }
};
