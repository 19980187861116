import React, {useEffect, useState} from 'react';
import moment from 'moment';

import './IngredientList.css';

const IngredientList = props => {

  const [now, setNow] = useState(new Date());
  
  useEffect(() => {
    const intervalID = setInterval(() => { 
        setNow(new Date());
        //console.log(new Date());
    }
        ,60000);

    return(() => {
        clearInterval(intervalID);
    })
  },[]);
  
  return (
    <section className="ingredient-list">
      <h2>Grocery List <span style={{float: 'right'}} >{props.ingredients.length} items</span></h2>
      <ul>
        {props.ingredients.map(ig => (
          <li key={ig.id} onClick={props.onRemoveItem.bind(this, ig.id)}>
            <span>{ig.title}</span>
            
            <div className='tag'>
            
            <div className='created'>{ig.created ? moment(ig.created.toDate()).from(now) : null}</div>
            <div className='user-initial'>{ig.username.charAt(0)}</div>
            </div>
            
          </li>
        ))}
      </ul>
    </section>
  );
};

export default IngredientList;
